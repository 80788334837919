exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EspPreviewComponent_espPreview_1k6Kl{\n    display: inline-block;\n    /* display:none;*/\n    /*border: 1px solid rgba(0,0,0,0.5);*/\n    /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n    height:30px;\n    width:50px;\n\n    vertical-align: top;\n\n    margin-top: 8px;\n    /*margin-left: 18px;*/\n    /*border-radius: 18px;*/\n    color:white;\n\n    font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n}\n\n\n.EspPreviewComponent_esp_connection_status_1Iqmy{\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display:none;\n}\n\n\n.EspPreviewComponent_esp_status_connected_2c1h6{\n   /* background-color: green;*/\n   background-image: url('/static/robbo_assets/esp_status_connected.png');\n   background-repeat: no-repeat;\n}\n\n\n.EspPreviewComponent_esp_status_disconnected_2ppUU{\n\n   /* background-color: red;*/\n\n    background-image: url('/static/robbo_assets/esp_status_disconnected.png');\n    background-repeat: no-repeat;\n\n}\n\n\n.EspPreviewComponent_esp_loading_icon_wH-Gs{\n    background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n    /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n    display:none;\n}\n\n\n.EspPreviewComponent_espPreviewPic_1Pm8c{\n    display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n}\n\n\n.EspPreviewComponent_esp_loading_icon_hidden_Bsyyf{\n    display:none;\n}\n\n\n.EspPreviewComponent_esp_loading_icon_showing_2eihI{\n    display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"espPreview": "EspPreviewComponent_espPreview_1k6Kl",
	"esp_connection_status": "EspPreviewComponent_esp_connection_status_1Iqmy",
	"espConnectionStatus": "EspPreviewComponent_esp_connection_status_1Iqmy",
	"esp_status_connected": "EspPreviewComponent_esp_status_connected_2c1h6",
	"espStatusConnected": "EspPreviewComponent_esp_status_connected_2c1h6",
	"esp_status_disconnected": "EspPreviewComponent_esp_status_disconnected_2ppUU",
	"espStatusDisconnected": "EspPreviewComponent_esp_status_disconnected_2ppUU",
	"esp_loading_icon": "EspPreviewComponent_esp_loading_icon_wH-Gs",
	"espLoadingIcon": "EspPreviewComponent_esp_loading_icon_wH-Gs",
	"espPreviewPic": "EspPreviewComponent_espPreviewPic_1Pm8c",
	"esp_loading_icon_hidden": "EspPreviewComponent_esp_loading_icon_hidden_Bsyyf",
	"espLoadingIconHidden": "EspPreviewComponent_esp_loading_icon_hidden_Bsyyf",
	"esp_loading_icon_showing": "EspPreviewComponent_esp_loading_icon_showing_2eihI",
	"espLoadingIconShowing": "EspPreviewComponent_esp_loading_icon_showing_2eihI"
};