exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IotConnectionComponent_iot_connection_tittle_I3QtM{\n\n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color:  #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.IotConnectionComponent_close_icon_13yQ5{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n\n.IotConnectionComponent_connection_true_icon_2MG5p{\n    float:right;\n    background-color: #00af41 ;\n    width: 10px;\n    height: 10px;\n    border-radius: 10px;\n}\n\n.IotConnectionComponent_connection_false_icon_35P_0{\n    float:right;\n    background-color: rgb(255, 0, 0) ;\n    width: 10px;\n    height: 10px;\n    border-radius: 10px;\n}\n\n.IotConnectionComponent_iot_connection_27xWO{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid  #00af41;\n\n    \n\n   height:100%;\n\n   min-width: 800px; /*650*/\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.IotConnectionComponent_iot_connection_content_1Hqkz{\n\n\n\n}\n\n.IotConnectionComponent_iot_connection_content_raw_1IIan{\n\n    margin: 15px 10px;\n\n}\n\n.IotConnectionComponent_iot_connection_content_column_Ash81{\n\n    display: inline-block;\n    margin-left: 10px;\n    margin-right: 10px;\n    min-width: 200px;\n\n}\n\n.IotConnectionComponent_iot_connection_value_column_3hT7r{\n\n    display: inline-block;\n    margin-left:10px;\n    margin-right: 10px;\n    min-width: 310px;\n\n}\n", ""]);

// exports
exports.locals = {
	"iot_connection_tittle": "IotConnectionComponent_iot_connection_tittle_I3QtM",
	"iotConnectionTittle": "IotConnectionComponent_iot_connection_tittle_I3QtM",
	"close_icon": "IotConnectionComponent_close_icon_13yQ5",
	"closeIcon": "IotConnectionComponent_close_icon_13yQ5",
	"connection_true_icon": "IotConnectionComponent_connection_true_icon_2MG5p",
	"connectionTrueIcon": "IotConnectionComponent_connection_true_icon_2MG5p",
	"connection_false_icon": "IotConnectionComponent_connection_false_icon_35P_0",
	"connectionFalseIcon": "IotConnectionComponent_connection_false_icon_35P_0",
	"iot_connection": "IotConnectionComponent_iot_connection_27xWO",
	"iotConnection": "IotConnectionComponent_iot_connection_27xWO",
	"iot_connection_content": "IotConnectionComponent_iot_connection_content_1Hqkz",
	"iotConnectionContent": "IotConnectionComponent_iot_connection_content_1Hqkz",
	"iot_connection_content_raw": "IotConnectionComponent_iot_connection_content_raw_1IIan",
	"iotConnectionContentRaw": "IotConnectionComponent_iot_connection_content_raw_1IIan",
	"iot_connection_content_column": "IotConnectionComponent_iot_connection_content_column_Ash81",
	"iotConnectionContentColumn": "IotConnectionComponent_iot_connection_content_column_Ash81",
	"iot_connection_value_column": "IotConnectionComponent_iot_connection_value_column_3hT7r",
	"iotConnectionValueColumn": "IotConnectionComponent_iot_connection_value_column_3hT7r"
};