exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SensorChooseWindowComponentElement_sensor_choose_window_component_element_39QOX{\n\n      display: inline-block;\n\n}\n\n.SensorChooseWindowComponentElement_sensor_choose_window_component_element_39QOX:hover{\n\n    border:1px outset #CCCCCC;\n    /*background-color: #CCCCCC;*/\n}\n", ""]);

// exports
exports.locals = {
	"sensor_choose_window_component_element": "SensorChooseWindowComponentElement_sensor_choose_window_component_element_39QOX",
	"sensorChooseWindowComponentElement": "SensorChooseWindowComponentElement_sensor_choose_window_component_element_39QOX"
};