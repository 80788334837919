exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.QuadcopterPreviewComponent_quadcopterPreview_TS6xv{\n\n  display: inline-block;\n\n  border: 1px solid rgba(0,0,0,0.5);\n\n  /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n  height:30px;\n  width:50px;\n\n  vertical-align: top;\n\n  margin-top: 5px;\n  margin-left: 20px;\n\n  border-radius: 18px;\n\n  color:white;\n\n  font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_connection_status_2uz88{\n\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display:none;\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_status_connected_2hXzF{\n\n    background-color: green;\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_status_disconnected_hyRV4{\n\n    background-color: red;\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_loading_icon_11lsZ{\n\n     background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n  /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n    display:none;\n\n\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopterPreviewPic_1wXKV{\n\n    display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_loading_icon_hidden_2teTr{\n\n      display:none;\n\n}\n\n\n.QuadcopterPreviewComponent_quadcopter_loading_icon_showing_1_nO-{\n\n     display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"quadcopterPreview": "QuadcopterPreviewComponent_quadcopterPreview_TS6xv",
	"quadcopter_connection_status": "QuadcopterPreviewComponent_quadcopter_connection_status_2uz88",
	"quadcopterConnectionStatus": "QuadcopterPreviewComponent_quadcopter_connection_status_2uz88",
	"quadcopter_status_connected": "QuadcopterPreviewComponent_quadcopter_status_connected_2hXzF",
	"quadcopterStatusConnected": "QuadcopterPreviewComponent_quadcopter_status_connected_2hXzF",
	"quadcopter_status_disconnected": "QuadcopterPreviewComponent_quadcopter_status_disconnected_hyRV4",
	"quadcopterStatusDisconnected": "QuadcopterPreviewComponent_quadcopter_status_disconnected_hyRV4",
	"quadcopter_loading_icon": "QuadcopterPreviewComponent_quadcopter_loading_icon_11lsZ",
	"quadcopterLoadingIcon": "QuadcopterPreviewComponent_quadcopter_loading_icon_11lsZ",
	"quadcopterPreviewPic": "QuadcopterPreviewComponent_quadcopterPreviewPic_1wXKV",
	"quadcopter_loading_icon_hidden": "QuadcopterPreviewComponent_quadcopter_loading_icon_hidden_2teTr",
	"quadcopterLoadingIconHidden": "QuadcopterPreviewComponent_quadcopter_loading_icon_hidden_2teTr",
	"quadcopter_loading_icon_showing": "QuadcopterPreviewComponent_quadcopter_loading_icon_showing_1_nO-",
	"quadcopterLoadingIconShowing": "QuadcopterPreviewComponent_quadcopter_loading_icon_showing_1_nO-"
};