exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ArduinoPalleteComponent_arduino_palette_Zrx9h{\n\n    display: inline-block;\n    /*display:none;*/\n    margin-left: 5%;\n\n   border: 1px solid #00af41;\n\n   height:100%;\n\n   min-width: 400px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.ArduinoPalleteComponent_arduino_panel_tittle_1vm_H{\n\n  background-color: #00af41;\n  color: white;\n  font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;  \n\nheight: 30px;\n\ntext-align: center;\n\npadding-top: 3px;\n\n}\n\n.ArduinoPalleteComponent_close_icon_oTagb{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n   \n\n    cursor: pointer;\n\n}\n\n.ArduinoPalleteComponent_column_2dgR1{\n\n    display: inline-block;\n    margin-left:10px;\n    margin-right:10px;\n}\n", ""]);

// exports
exports.locals = {
	"arduino_palette": "ArduinoPalleteComponent_arduino_palette_Zrx9h",
	"arduinoPalette": "ArduinoPalleteComponent_arduino_palette_Zrx9h",
	"arduino_panel_tittle": "ArduinoPalleteComponent_arduino_panel_tittle_1vm_H",
	"arduinoPanelTittle": "ArduinoPalleteComponent_arduino_panel_tittle_1vm_H",
	"close_icon": "ArduinoPalleteComponent_close_icon_oTagb",
	"closeIcon": "ArduinoPalleteComponent_close_icon_oTagb",
	"column": "ArduinoPalleteComponent_column_2dgR1"
};