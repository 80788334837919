exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LaboratoryPalleteComponent_lab_palette_1Boiw{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid #00af41;\n\n   \n\n   height:100%;\n\n   min-width: 250px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.LaboratoryPalleteComponent_lab_panel_tittle_1lIVc{\n\n  \n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.LaboratoryPalleteComponent_close_icon_2SBa4{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n    cursor: pointer;\n\n}\n", ""]);

// exports
exports.locals = {
	"lab_palette": "LaboratoryPalleteComponent_lab_palette_1Boiw",
	"labPalette": "LaboratoryPalleteComponent_lab_palette_1Boiw",
	"lab_panel_tittle": "LaboratoryPalleteComponent_lab_panel_tittle_1lIVc",
	"labPanelTittle": "LaboratoryPalleteComponent_lab_panel_tittle_1lIVc",
	"close_icon": "LaboratoryPalleteComponent_close_icon_2SBa4",
	"closeIcon": "LaboratoryPalleteComponent_close_icon_2SBa4"
};