exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OttoPalleteComponent_otto_palette_1SbPX{\n\n    display: inline-block;\n    margin-left: 5%;\n\n   \n    border: 1px solid #00af41;\n\n   height:100%;\n\n   min-width: 330px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.OttoPalleteComponent_otto_panel_tittle_2F7uL{\n\n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.OttoPalleteComponent_close_icon_3M7px{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n", ""]);

// exports
exports.locals = {
	"otto_palette": "OttoPalleteComponent_otto_palette_1SbPX",
	"ottoPalette": "OttoPalleteComponent_otto_palette_1SbPX",
	"otto_panel_tittle": "OttoPalleteComponent_otto_panel_tittle_2F7uL",
	"ottoPanelTittle": "OttoPalleteComponent_otto_panel_tittle_2F7uL",
	"close_icon": "OttoPalleteComponent_close_icon_3M7px",
	"closeIcon": "OttoPalleteComponent_close_icon_3M7px"
};