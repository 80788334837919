exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AboutWindowComponent_about_window_tittle_2ortq{\n\n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.AboutWindowComponent_close_icon_17b7Q{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n\n.AboutWindowComponent_about_window_2UbVx{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid #00af41;\n\n    \n\n   height:100%;\n\n   min-width: 800px; /*650*/\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.AboutWindowComponent_about_window_content_YF_FC{\n\n\n\n}\n\n.AboutWindowComponent_about_window_content_raw_1DZ6R{\n\n    margin: 15px 10px;\n\n}\n\n.AboutWindowComponent_about_window_content_column_1V6L3{\n\n    display: inline-block;\n    margin-left: 10px;\n    margin-right: 10px;\n    min-width: 200px;\n\n}\n\n.AboutWindowComponent_about_window_value_column_1SZIV{\n\n    display: inline-block;\n    margin-left:10px;\n    margin-right: 10px;\n    min-width: 310px;\n\n}\n", ""]);

// exports
exports.locals = {
	"about_window_tittle": "AboutWindowComponent_about_window_tittle_2ortq",
	"aboutWindowTittle": "AboutWindowComponent_about_window_tittle_2ortq",
	"close_icon": "AboutWindowComponent_close_icon_17b7Q",
	"closeIcon": "AboutWindowComponent_close_icon_17b7Q",
	"about_window": "AboutWindowComponent_about_window_2UbVx",
	"aboutWindow": "AboutWindowComponent_about_window_2UbVx",
	"about_window_content": "AboutWindowComponent_about_window_content_YF_FC",
	"aboutWindowContent": "AboutWindowComponent_about_window_content_YF_FC",
	"about_window_content_raw": "AboutWindowComponent_about_window_content_raw_1DZ6R",
	"aboutWindowContentRaw": "AboutWindowComponent_about_window_content_raw_1DZ6R",
	"about_window_content_column": "AboutWindowComponent_about_window_content_column_1V6L3",
	"aboutWindowContentColumn": "AboutWindowComponent_about_window_content_column_1V6L3",
	"about_window_value_column": "AboutWindowComponent_about_window_value_column_1SZIV",
	"aboutWindowValueColumn": "AboutWindowComponent_about_window_value_column_1SZIV"
};