exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OttoPreviewComponent_ottoPreview_RLqoy{\n\n \n    display: inline-block;\n   /* display:none;*/\n\n  /*border: 1px solid rgba(0,0,0,0.5);*/\n\n  /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n  height:30px;\n  width:50px;\n\n  vertical-align: top;\n\n  margin-top: 8px;\n  /*margin-left: 18px;*/\n\n  /*border-radius: 18px;*/\n\n  color:white;\n\n  font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n\n\n}\n\n\n.OttoPreviewComponent_otto_connection_status_1i4I0{\n\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display: none;\n}\n\n\n.OttoPreviewComponent_otto_status_connected_17mNl{\n\n   background-image: url('/static/robbo_assets/otto_status_connected.png');\n   background-repeat: no-repeat;\n\n}\n\n\n.OttoPreviewComponent_otto_status_disconnected_35nFO{\n\n   background-image: url('/static/robbo_assets/otto_status_disconnected.png');\n   background-repeat: no-repeat;\n\n}\n\n\n.OttoPreviewComponent_otto_loading_icon_3xkhJ{\n\n     background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n  /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n     display: none;\n\n\n\n}\n\n\n.OttoPreviewComponent_ottoPreviewPic_L5MAG{\n\n    display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n\n}\n\n\n.OttoPreviewComponent_otto_loading_icon_hidden_25Flz{\n\n      display:none;\n\n}\n\n\n.OttoPreviewComponent_otto_loading_icon_showing_1BxCD{\n\n     display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"ottoPreview": "OttoPreviewComponent_ottoPreview_RLqoy",
	"otto_connection_status": "OttoPreviewComponent_otto_connection_status_1i4I0",
	"ottoConnectionStatus": "OttoPreviewComponent_otto_connection_status_1i4I0",
	"otto_status_connected": "OttoPreviewComponent_otto_status_connected_17mNl",
	"ottoStatusConnected": "OttoPreviewComponent_otto_status_connected_17mNl",
	"otto_status_disconnected": "OttoPreviewComponent_otto_status_disconnected_35nFO",
	"ottoStatusDisconnected": "OttoPreviewComponent_otto_status_disconnected_35nFO",
	"otto_loading_icon": "OttoPreviewComponent_otto_loading_icon_3xkhJ",
	"ottoLoadingIcon": "OttoPreviewComponent_otto_loading_icon_3xkhJ",
	"ottoPreviewPic": "OttoPreviewComponent_ottoPreviewPic_L5MAG",
	"otto_loading_icon_hidden": "OttoPreviewComponent_otto_loading_icon_hidden_25Flz",
	"ottoLoadingIconHidden": "OttoPreviewComponent_otto_loading_icon_hidden_25Flz",
	"otto_loading_icon_showing": "OttoPreviewComponent_otto_loading_icon_showing_1BxCD",
	"ottoLoadingIconShowing": "OttoPreviewComponent_otto_loading_icon_showing_1BxCD"
};