exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LaboratoryPreviewComponent_labPreview_2f9lr{\n\n  display: inline-block;\n\n  /*border: 1px solid rgba(0,0,0,0.5);*/\n\n  /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n  height:30px;\n  width:50px;\n\n  vertical-align: top;\n\n  margin-top: 8px;\n  /*margin-left: 20px;*/\n\n  /*border-radius: 18px;*/\n\n  color:white;\n\n  font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n\n\n}\n\n\n.LaboratoryPreviewComponent_lab_connection_status_2o7BE{\n\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display:none;\n}\n\n\n.LaboratoryPreviewComponent_lab_status_connected_1ck4G{\n\n    /*background-color: green;*/\n\n     background-image: url('/static/robbo_assets/lab_status_connected.png');\n     background-repeat: no-repeat;\n\n}\n\n\n.LaboratoryPreviewComponent_lab_status_disconnected__2NOo{\n\n   /* background-color: red;*/\n\n    background-image: url('/static/robbo_assets/lab_status_disconnected.png');\n    background-repeat: no-repeat;\n\n}\n\n\n.LaboratoryPreviewComponent_lab_loading_icon_1t9-d{\n\n     background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n  /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n    display:none;\n\n\n\n}\n\n\n.LaboratoryPreviewComponent_labPreviewPic_1Ti7q{\n\n   display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n\n}\n\n\n.LaboratoryPreviewComponent_lab_loading_icon_hidden_yR1fo{\n\n      display:none;\n\n}\n\n\n.LaboratoryPreviewComponent_lab_loading_icon_showing_10Tav{\n\n     display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"labPreview": "LaboratoryPreviewComponent_labPreview_2f9lr",
	"lab_connection_status": "LaboratoryPreviewComponent_lab_connection_status_2o7BE",
	"labConnectionStatus": "LaboratoryPreviewComponent_lab_connection_status_2o7BE",
	"lab_status_connected": "LaboratoryPreviewComponent_lab_status_connected_1ck4G",
	"labStatusConnected": "LaboratoryPreviewComponent_lab_status_connected_1ck4G",
	"lab_status_disconnected": "LaboratoryPreviewComponent_lab_status_disconnected__2NOo",
	"labStatusDisconnected": "LaboratoryPreviewComponent_lab_status_disconnected__2NOo",
	"lab_loading_icon": "LaboratoryPreviewComponent_lab_loading_icon_1t9-d",
	"labLoadingIcon": "LaboratoryPreviewComponent_lab_loading_icon_1t9-d",
	"labPreviewPic": "LaboratoryPreviewComponent_labPreviewPic_1Ti7q",
	"lab_loading_icon_hidden": "LaboratoryPreviewComponent_lab_loading_icon_hidden_yR1fo",
	"labLoadingIconHidden": "LaboratoryPreviewComponent_lab_loading_icon_hidden_yR1fo",
	"lab_loading_icon_showing": "LaboratoryPreviewComponent_lab_loading_icon_showing_10Tav",
	"labLoadingIconShowing": "LaboratoryPreviewComponent_lab_loading_icon_showing_10Tav"
};