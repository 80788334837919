exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ArduinoPreviewComponent_arduinoPreview_V_95Y{\n\n    display: inline-block;\n   /* display:none;*/\n\n  /*border: 1px solid rgba(0,0,0,0.5);*/\n\n  /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n  height:30px;\n  width:50px;\n\n  vertical-align: top;\n\n  margin-top: 8px;\n  /*margin-left: 18px;*/\n\n  /*border-radius: 18px;*/\n\n  color:white;\n\n  font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n\n\n}\n\n\n.ArduinoPreviewComponent_arduino_connection_status_2rfe4{\n\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display:none;\n}\n\n\n.ArduinoPreviewComponent_arduino_status_connected_3pmVJ{\n\n   /* background-color: green;*/\n   background-image: url('/static/robbo_assets/arduino_status_connected.png');\n   background-repeat: no-repeat;\n\n}\n\n\n.ArduinoPreviewComponent_arduino_status_disconnected_1hpzG{\n\n   /* background-color: red;*/\n\n    background-image: url('/static/robbo_assets/arduino_status_disconnected.png');\n    background-repeat: no-repeat;\n\n}\n\n\n.ArduinoPreviewComponent_arduino_loading_icon_6-sKy{\n\n     background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n  /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n    display:none;\n\n\n\n}\n\n\n.ArduinoPreviewComponent_arduinoPreviewPic_1MncJ{\n\n    display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n\n}\n\n\n.ArduinoPreviewComponent_arduino_loading_icon_hidden_2VLBh{\n\n      display:none;\n\n}\n\n\n.ArduinoPreviewComponent_arduino_loading_icon_showing_2w6Vx{\n\n     display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"arduinoPreview": "ArduinoPreviewComponent_arduinoPreview_V_95Y",
	"arduino_connection_status": "ArduinoPreviewComponent_arduino_connection_status_2rfe4",
	"arduinoConnectionStatus": "ArduinoPreviewComponent_arduino_connection_status_2rfe4",
	"arduino_status_connected": "ArduinoPreviewComponent_arduino_status_connected_3pmVJ",
	"arduinoStatusConnected": "ArduinoPreviewComponent_arduino_status_connected_3pmVJ",
	"arduino_status_disconnected": "ArduinoPreviewComponent_arduino_status_disconnected_1hpzG",
	"arduinoStatusDisconnected": "ArduinoPreviewComponent_arduino_status_disconnected_1hpzG",
	"arduino_loading_icon": "ArduinoPreviewComponent_arduino_loading_icon_6-sKy",
	"arduinoLoadingIcon": "ArduinoPreviewComponent_arduino_loading_icon_6-sKy",
	"arduinoPreviewPic": "ArduinoPreviewComponent_arduinoPreviewPic_1MncJ",
	"arduino_loading_icon_hidden": "ArduinoPreviewComponent_arduino_loading_icon_hidden_2VLBh",
	"arduinoLoadingIconHidden": "ArduinoPreviewComponent_arduino_loading_icon_hidden_2VLBh",
	"arduino_loading_icon_showing": "ArduinoPreviewComponent_arduino_loading_icon_showing_2w6Vx",
	"arduinoLoadingIconShowing": "ArduinoPreviewComponent_arduino_loading_icon_showing_2w6Vx"
};