exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SettingsWindowComponent_settings_window_tittle_CSEI3{\n\n   background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n   \n    padding-top: 3px;\n\n\nheight: 30px;\n\ntext-align: center;\n\n}\n\n.SettingsWindowComponent_close_icon_2Rvq6{\n\n     float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n\n.SettingsWindowComponent_settings_window_5Xxcr{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid #00af41;\n\n   height:100%;\n\n   min-width: 900px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n}\n\n.SettingsWindowComponent_settings_window_content_3V1Lf{\n\n\n\n}\n\n.SettingsWindowComponent_settings_window_content_raw_ihjHc{\n\n    margin: 10px 10px;\n\n}\n\n.SettingsWindowComponent_settings_window_content_column_2wF0j{\n\n    display: inline-block;\n    margin-left: 10px;\n    margin-right: 10px;\n   /* min-width: 400px;*/\n\n}\n", ""]);

// exports
exports.locals = {
	"settings_window_tittle": "SettingsWindowComponent_settings_window_tittle_CSEI3",
	"settingsWindowTittle": "SettingsWindowComponent_settings_window_tittle_CSEI3",
	"close_icon": "SettingsWindowComponent_close_icon_2Rvq6",
	"closeIcon": "SettingsWindowComponent_close_icon_2Rvq6",
	"settings_window": "SettingsWindowComponent_settings_window_5Xxcr",
	"settingsWindow": "SettingsWindowComponent_settings_window_5Xxcr",
	"settings_window_content": "SettingsWindowComponent_settings_window_content_3V1Lf",
	"settingsWindowContent": "SettingsWindowComponent_settings_window_content_3V1Lf",
	"settings_window_content_raw": "SettingsWindowComponent_settings_window_content_raw_ihjHc",
	"settingsWindowContentRaw": "SettingsWindowComponent_settings_window_content_raw_ihjHc",
	"settings_window_content_column": "SettingsWindowComponent_settings_window_content_column_2wF0j",
	"settingsWindowContentColumn": "SettingsWindowComponent_settings_window_content_column_2wF0j"
};