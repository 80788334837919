exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n\n.FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_3_jvP{\n\n\n  display: inline-block;\n  margin-left: 5%;\n\n  border: 1px solid #00af41;\n\n \n\n height:100%;\n max-height: 300px;\n\n\n min-width: 500px;\n\n vertical-align: top;\n\n background-color: white;\n\n}\n\n.FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_tittle_2ZJF9{\n\n background-color: #00af41;\n  color: white;\n  font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;  \n\nheight: 30px;\n\ntext-align: center;\n\npadding-top: 3px;\n\n\n\n}\n\n.FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_content_eqfRk{\n\n    overflow:scroll;\n    max-height: 200px;\n    margin-top:7px;\n\n}\n\n.FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_status__Ddr2{\n\n\n      margin: 20px 20px;\n      font-weight: bold;\n      font-size: 16px;\n\n\n}\n\n.FirmwareFlasherFlashingStatusComponent_close_icon_2Zof6{\n\n    float:right;\n    background-image: url('/build/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n", ""]);

// exports
exports.locals = {
	"firmware_flasher_flashing_status_component": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_3_jvP",
	"firmwareFlasherFlashingStatusComponent": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_3_jvP",
	"firmware_flasher_flashing_status_component_tittle": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_tittle_2ZJF9",
	"firmwareFlasherFlashingStatusComponentTittle": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_tittle_2ZJF9",
	"firmware_flasher_flashing_status_component_log_content": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_content_eqfRk",
	"firmwareFlasherFlashingStatusComponentLogContent": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_content_eqfRk",
	"firmware_flasher_flashing_status_component_log_status": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_status__Ddr2",
	"firmwareFlasherFlashingStatusComponentLogStatus": "FirmwareFlasherFlashingStatusComponent_firmware_flasher_flashing_status_component_log_status__Ddr2",
	"close_icon": "FirmwareFlasherFlashingStatusComponent_close_icon_2Zof6",
	"closeIcon": "FirmwareFlasherFlashingStatusComponent_close_icon_2Zof6"
};