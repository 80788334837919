exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SensorComponent_sensor_block_field_2IQBY{\n\n     display: inline-block;\n     margin: 2px 1px;\n\n\n\n\n}\n\n.SensorComponent_sensor_choose_icon_16Czf:hover{\n\n\n      cursor: pointer;\n\n}\n\n.SensorComponent_sensor_1zCb5{\n\n  /*  display: inline-block; */\n\n    padding-left: 40px;\n\n}\n", ""]);

// exports
exports.locals = {
	"sensor_block_field": "SensorComponent_sensor_block_field_2IQBY",
	"sensorBlockField": "SensorComponent_sensor_block_field_2IQBY",
	"sensor_choose_icon": "SensorComponent_sensor_choose_icon_16Czf",
	"sensorChooseIcon": "SensorComponent_sensor_choose_icon_16Czf",
	"sensor": "SensorComponent_sensor_1zCb5"
};