exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n\n\n\n.RobotPalleteComponent_robot_palette_2k1_L{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid #00af41;\n\n   /* box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n   height:100%;\n\n   min-width: 330px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n\n}\n\n.RobotPalleteComponent_robot_panel_tittle_2LHmb{\n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.RobotPalleteComponent_close_icon_1VLfe{\n\n    float:right;\n    background-image: url('/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n", ""]);

// exports
exports.locals = {
	"robot_palette": "RobotPalleteComponent_robot_palette_2k1_L",
	"robotPalette": "RobotPalleteComponent_robot_palette_2k1_L",
	"robot_panel_tittle": "RobotPalleteComponent_robot_panel_tittle_2LHmb",
	"robotPanelTittle": "RobotPalleteComponent_robot_panel_tittle_2LHmb",
	"close_icon": "RobotPalleteComponent_close_icon_1VLfe",
	"closeIcon": "RobotPalleteComponent_close_icon_1VLfe"
};