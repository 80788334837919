exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RobotPreviewComponent_robotPreview_-QKRX{\n\n  display: inline-block;\n\n  /*border: 1px solid rgba(0,0,0,0.5);*/\n\n  /*box-shadow: 4px 4px 10px rgba(0,0,0,0.5);*/\n\n  height:30px;\n  width:50px;\n\n  vertical-align: top;\n\n  margin-top: 8px;\n  margin-left: 20px;\n\n /* border-radius: 18px;*/\n\n  color:white;\n\n  font-family: \"Proxima Nova Extrabold\",Helvetica,Arial, sans-serif;\n\n\n}\n\n\n.RobotPreviewComponent_robot_connection_status_3tUiO{\n\n    border-width: 2px;\n    border-radius: 5px;\n    border-style: solid;\n    display: inline-block;\n    margin: 5px 5px;\n    min-height: 15px;\n    min-width: 15px;\n    display:none;\n}\n\n\n.RobotPreviewComponent_robot_status_connected_1qEAi{\n\n     \n   background-image: url('/static/robbo_assets/robot_status_connected.png');\n   background-repeat: no-repeat;\n\n}\n\n\n.RobotPreviewComponent_robot_status_disconnected_2Cm9n{\n\n   \n   background-image: url('/static/robbo_assets/robot_status_disconnected.png');\n   background-repeat: no-repeat;\n\n}\n\n\n.RobotPreviewComponent_robot_loading_icon_hWQ3u{\n\n     background-image: url(/build/static/robbo_assets/searching.gif);\n    /* background-size: 30px; */\n    min-width: 20px;\n    min-height: 20px;\n    /* max-width: 30px; */\n  /*  display: inline-block; */\n    margin-left: 5px;\n    margin-right: 5px;\n    display:none;\n\n\n\n}\n\n\n.RobotPreviewComponent_robotPreviewPic_2BbEp{\n\n    display: inline-block;\n    margin-left: 19px;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n    vertical-align: middle;\n    margin-top: 4px;\n    font-weight: bold;\n    font-size: medium;\n\n}\n\n\n.RobotPreviewComponent_robot_loading_icon_hidden_gZh3A{\n\n      display:none;\n\n}\n\n\n.RobotPreviewComponent_robot_loading_icon_showing_pdJOy{\n\n     display: inline-block;\n}\n", ""]);

// exports
exports.locals = {
	"robotPreview": "RobotPreviewComponent_robotPreview_-QKRX",
	"robot_connection_status": "RobotPreviewComponent_robot_connection_status_3tUiO",
	"robotConnectionStatus": "RobotPreviewComponent_robot_connection_status_3tUiO",
	"robot_status_connected": "RobotPreviewComponent_robot_status_connected_1qEAi",
	"robotStatusConnected": "RobotPreviewComponent_robot_status_connected_1qEAi",
	"robot_status_disconnected": "RobotPreviewComponent_robot_status_disconnected_2Cm9n",
	"robotStatusDisconnected": "RobotPreviewComponent_robot_status_disconnected_2Cm9n",
	"robot_loading_icon": "RobotPreviewComponent_robot_loading_icon_hWQ3u",
	"robotLoadingIcon": "RobotPreviewComponent_robot_loading_icon_hWQ3u",
	"robotPreviewPic": "RobotPreviewComponent_robotPreviewPic_2BbEp",
	"robot_loading_icon_hidden": "RobotPreviewComponent_robot_loading_icon_hidden_gZh3A",
	"robotLoadingIconHidden": "RobotPreviewComponent_robot_loading_icon_hidden_gZh3A",
	"robot_loading_icon_showing": "RobotPreviewComponent_robot_loading_icon_showing_pdJOy",
	"robotLoadingIconShowing": "RobotPreviewComponent_robot_loading_icon_showing_pdJOy"
};