exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SensorChooseWindowComponent_sensor_choose_window_2y9y1{\n\n  z-index: 1000000 !important;\n/*background-color: #AAAAAA;*/\n/*position: absolute;\ntop:40%;\nleft:20%;*/\n\nborder: 1px solid #00af41;\n\n\n\n\n}\n\n.SensorChooseWindowComponent_sensor_choose_window_components_block_2LNNs{\n\n\n    width:100%;\n    background-color: white;\n\n\n}\n\n.SensorChooseWindowComponent_window_show_3AElc{\n\n\n    display: block;\n\n}\n\n.SensorChooseWindowComponent_window_drag_1cZSj{\n\n\n        opacity:  0.5;\n        cursor: move;\n        background-color: yellow;\n\n}\n\n.SensorChooseWindowComponent_window_hide_mmKXU{\n\n\n    display: none;\n\n}\n\n.SensorChooseWindowComponent_sensor_choose_window_tittle_1fHJH{\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n   \n    padding-top: 3px;\n\n\n\n    height: 30px;\n\n    text-align: center;\n\n}\n\n.SensorChooseWindowComponent_sensor-choose-window-tittle-old_sW-P-{\n\n\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a7c7dc+0,86b0ce+55,77abd1+100 */\n  background: #a7c7dc; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */\n  background: -webkit-gradient(linear, left top, left bottom, from(#a7c7dc),color-stop(55%, #86b0ce),to(#77abd1));\n  background: -o-linear-gradient(top, #a7c7dc 0%,#86b0ce 55%,#77abd1 100%);\n  background: linear-gradient(to bottom, #a7c7dc 0%,#86b0ce 55%,#77abd1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7c7dc', endColorstr='#77abd1',GradientType=0 ); /* IE6-9 */\n\n\n\n}\n", ""]);

// exports
exports.locals = {
	"sensor_choose_window": "SensorChooseWindowComponent_sensor_choose_window_2y9y1",
	"sensorChooseWindow": "SensorChooseWindowComponent_sensor_choose_window_2y9y1",
	"sensor_choose_window_components_block": "SensorChooseWindowComponent_sensor_choose_window_components_block_2LNNs",
	"sensorChooseWindowComponentsBlock": "SensorChooseWindowComponent_sensor_choose_window_components_block_2LNNs",
	"window_show": "SensorChooseWindowComponent_window_show_3AElc",
	"windowShow": "SensorChooseWindowComponent_window_show_3AElc",
	"window_drag": "SensorChooseWindowComponent_window_drag_1cZSj",
	"windowDrag": "SensorChooseWindowComponent_window_drag_1cZSj",
	"window_hide": "SensorChooseWindowComponent_window_hide_mmKXU",
	"windowHide": "SensorChooseWindowComponent_window_hide_mmKXU",
	"sensor_choose_window_tittle": "SensorChooseWindowComponent_sensor_choose_window_tittle_1fHJH",
	"sensorChooseWindowTittle": "SensorChooseWindowComponent_sensor_choose_window_tittle_1fHJH",
	"sensor-choose-window-tittle-old": "SensorChooseWindowComponent_sensor-choose-window-tittle-old_sW-P-",
	"sensorChooseWindowTittleOld": "SensorChooseWindowComponent_sensor-choose-window-tittle-old_sW-P-"
};