exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.QuadcopterPalleteComponent_quadcopter_palette_iWwHL{\n\n    display: inline-block;\n    margin-left: 5%;\n\n    border: 1px solid #00af41;\n\n   height:100%;\n\n   min-width: 330px;\n\n   vertical-align: top;\n\n   background-color: white;\n\n\n}\n\n.QuadcopterPalleteComponent_quadcopter_panel_tittle_2VXR1{\n\n  \n\n\n    height: 30px;\n\n    text-align: center;\n\n    background-color: #00af41;\n    color: white;\n    font-family: \"Proxima Nova Regular\",Helvetica,Arial, sans-serif;\n    /* vertical-align: middle; */\n    padding-top: 3px;\n\n}\n\n.QuadcopterPalleteComponent_close_icon_2ZFOl{\n\n    float:right;\n    background-image: url('/build/static/robbo_assets/icon_close_window.png');\n    width: 27px;\n    height: 27px;\n\n    cursor: pointer;\n\n}\n\n.QuadcopterPalleteComponent_battery_low_yelolow_warning_30gnv{\n\n    background-color: yellow;\n}\n\n.QuadcopterPalleteComponent_battery_low_red_warning_FrDhj{\n\n    background-color: red;\n}\n", ""]);

// exports
exports.locals = {
	"quadcopter_palette": "QuadcopterPalleteComponent_quadcopter_palette_iWwHL",
	"quadcopterPalette": "QuadcopterPalleteComponent_quadcopter_palette_iWwHL",
	"quadcopter_panel_tittle": "QuadcopterPalleteComponent_quadcopter_panel_tittle_2VXR1",
	"quadcopterPanelTittle": "QuadcopterPalleteComponent_quadcopter_panel_tittle_2VXR1",
	"close_icon": "QuadcopterPalleteComponent_close_icon_2ZFOl",
	"closeIcon": "QuadcopterPalleteComponent_close_icon_2ZFOl",
	"battery_low_yelolow_warning": "QuadcopterPalleteComponent_battery_low_yelolow_warning_30gnv",
	"batteryLowYelolowWarning": "QuadcopterPalleteComponent_battery_low_yelolow_warning_30gnv",
	"battery_low_red_warning": "QuadcopterPalleteComponent_battery_low_red_warning_FrDhj",
	"batteryLowRedWarning": "QuadcopterPalleteComponent_battery_low_red_warning_FrDhj"
};