exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SensorPalleteCollapsed_sensors_panel_collapsed_2uuH5{\n\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b4ddb4+0,83c783+17,52b152+33,008a00+67,005700+83,002400+100;Green+3D+%231 */\n  background: #b4ddb4; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */\n  background: -webkit-gradient(linear, left top, left bottom, from(#b4ddb4),color-stop(17%, #83c783),color-stop(33%, #52b152),color-stop(67%, #008a00),color-stop(83%, #005700),to(#002400));\n  background: -o-linear-gradient(top, #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);\n  background: linear-gradient(to bottom, #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ddb4', endColorstr='#002400',GradientType=0 ); /* IE6-9 */\n\n\nheight: 30px;\nwidth:80%;\nmargin: 20px auto;\n\ntext-align: center;\n\n/*border: 2px outset rgba(0,0,0,0.5);*/\n\n-webkit-box-shadow: 4px 4px 10px rgba(0,0,0,0.5);\n\n        box-shadow: 4px 4px 10px rgba(0,0,0,0.5);\n\n}\n", ""]);

// exports
exports.locals = {
	"sensors_panel_collapsed": "SensorPalleteCollapsed_sensors_panel_collapsed_2uuH5",
	"sensorsPanelCollapsed": "SensorPalleteCollapsed_sensors_panel_collapsed_2uuH5"
};